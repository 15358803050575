/* Utils */
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: $borderRadius;

    &:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 991px) {
    .card {
        padding: 1rem;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}

.justify-content-right {
    justify-content: right;
}

.text-align-center {
    text-align: center !important;
}

.w-100 {
    width: 100%;
}



.p-field-radiobutton {
    padding: 0.5rem;
    
    p-radioButton {
        padding-right: 0.5rem;
    }
}